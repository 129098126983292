import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { APIService } from 'src/app/shared/services/api/api.service'
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Options } from 'select2';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  IsOTPSend: boolean = false;
  IsPassword: boolean = false;
  MobileNo: string = '';
  UserId: number = 0;
  OTP: string = '';
  ReceivedOTP: string = '';
  NewPassword: string = '';
  ConfirmPassword: string = '';
  constructor(private router: Router,private fb: FormBuilder, private APIService: APIService, private ActivatedRoute: ActivatedRoute, private Router: Router, private toastrService: ToastrService) {
  }

  ngOnInit(): void {
  }

  SendOTP() {
    debugger
    this.APIService.GetAPI('Auth/OTPWithForgotPasswordAdmin?Contact=' + this.MobileNo).subscribe(data => {
      if (data.response.statusCode == 1) {
        this.toastrService.success('OTP has been sent to entered mobile number.', 'OTP sent');
        this.IsOTPSend = true;
        this.IsPassword = false;
        this.ReceivedOTP = data.response.data.otp;
        this.UserId= data.response.data.userId;
      }
      else {
        this.toastrService.warning('No records found!', 'Not Found');
      }

    }, error => {
      this.toastrService.error('Something went Wrong', 'Error!');
    })
  }



  ValidateOTP(OTPValue) {
    debugger
    if (OTPValue.length == 6) {
      if (this.ReceivedOTP == OTPValue) {
        this.IsPassword = true;
        this.IsOTPSend = false;
      } else {
        this.toastrService.warning('Please enter Valid OTP.', 'OTP not Match!!');
      }
    }
  }

  UpdatePassword() {
    if (this.NewPassword == '') {
      this.toastrService.warning('Please enter new password.', 'Password Required');
      return false;
    }else if (this.NewPassword.length<8) {
      this.toastrService.warning('Password should be minimum 8 characters.', 'Invalid');
      return false;
    } else if (this.NewPassword != this.ConfirmPassword) {
      this.toastrService.warning('Password and confirm password should be same.', 'Password not match');
      return false;
    } else {
      this.APIService.GetAPI('Auth/ChangePassword?UserId=' + this.UserId + '&NewPassword=' + this.NewPassword).subscribe(data => {
        if (data.response.statusCode == 1) {
          this.toastrService.success('Password has been updated successfully.', 'Password Updated');
          this.IsOTPSend = true;
          this.IsPassword = false;
          this.ReceivedOTP = data.response.data.otp;
          this.router.navigateByUrl('/login');
        }
        else {
          this.toastrService.warning('No records found!', 'Not Found');
        }
      }, error => {
        this.toastrService.error('Something went Wrong', 'Error!');
      })

    }
  }

}
