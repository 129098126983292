
import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/firebase/auth.service';
import { DOCUMENT } from '@angular/common';
import { Subscription, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIService } from 'src/app/shared/services/api/api.service'
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

var body = document.getElementsByTagName("body")[0];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public menuItems: Menu[];
  public items: Menu[];
  public searchResult: boolean = false;
  public searchResultEmpty: boolean = false;
  public openNav: boolean = false
  public right_sidebar: boolean = false
  public text: string;
  public elem;
  public isOpenMobile: boolean = false
  timerSubscription: Subscription;
  public NotificationStatus: boolean = true;
  public NotificationIcon: string = 'bell-off';
  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  constructor(
    public navServices: NavService,
    @Inject(DOCUMENT) private document: any,
    private translate: TranslateService,
    public authService: AuthService,
    private APIService: APIService,
    private toastrService: ToastrService, private Router: Router
    , private modalService: NgbModal) {
    translate.setDefaultLang('en');
  }

  ngOnInit() {
    console.log(1)
    this.elem = document.documentElement;
    this.navServices.items.subscribe(menuItems => {
      this.items = menuItems
    });
    if (localStorage.getItem('notification') != null) {
      this.NotificationStatus = localStorage.getItem('notification') == 'true' ? true : false;
    } else {
      this.NotificationStatus = true;
    }

    if (this.NotificationStatus) {
      this.NotificationIcon = 'bell';
      this.StartOrderAlert();
    }
    // this.StartOrderAlert();
  }

  ngOnDestroy() {
    console.log('Alert data stopped')
    this.NotificationIcon = 'bell-off';
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.removeFix();
  }

  NotificationSwitch() {
    debugger
    this.NotificationStatus = !this.NotificationStatus;
    localStorage.setItem('notification', this.NotificationStatus.toString());
    if (this.timerSubscription) {
      console.log('Alert data stopped')
      this.NotificationIcon = 'bell-off';
      this.timerSubscription.unsubscribe();
    }

    if (this.NotificationStatus) {
      this.NotificationIcon = 'bell';
      this.StartOrderAlert();
    }
  }

  StartOrderAlert() {
    this.timerSubscription = timer(0, 300000).pipe(
      map(() => {
        console.log('Alert data Called')
        this.CheckNewOrders();
      })
    ).subscribe();
  }

  CheckNewOrders() {
    this.APIService.GetAPI('MobileMandiAdmin/AdminAlertOrderList').subscribe(data => {
      if (data.response.data) {
        if (data.response.data.length > 0) {
          let audio = new Audio();
          audio.src = "../../../assets/music/music.mp3";
          audio.load();
          audio.play();

          // Swal.fire({
          //   title: 'Order Recieved..',
          //   text:'You have ' + data.response.data.length + ' pending orders.',
          //   icon: 'info',
          //   showCancelButton: true,
          //   confirmButtonColor: '#3085d6',
          //   cancelButtonColor: '#d33',
          //   cancelButtonText:"Remind me leter",
          //   confirmButtonText: 'View Orders'
          // }).then((result) => {
          //   if (result.isConfirmed) {

          //   }
          // })

          Swal.fire({
            title: 'Order Recieved..',
            text: 'You have ' + data.response.data.length + ' pending orders.',
            icon: 'info',
            showCancelButton: true,

            cancelButtonColor: 'red',
            cancelButtonText: "Remind me leter",
            confirmButtonText: 'View Orders'
          }).then((result) => {
            if (result.isConfirmed) {
              audio.pause();
              audio.currentTime = 0;
              this.UpdateOrderAlertStatus();
            } else {
              audio.pause();
              audio.currentTime = 0;
            }
          })

          // Swal.fire('Order Recieved..', 'You have ' + data.response.data.length + ' pending orders.', 'success').then((result) => {
          //   audio.pause();
          //   audio.currentTime = 0;
          //   this.Router.navigateByUrl('order/processing-order');
          // })
        }
      }
    }, error => {
      this.toastrService.error('Something went Wrong', 'Error!');
    })
  }

  UpdateOrderAlertStatus() {
    this.APIService.GetAPI('MobileMandiAdmin/UpdateAdminAlertOrderList').subscribe(data => {
      console.log(data);
      this.Router.navigateByUrl('order/processing-order');
    })
  }


  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  collapseSidebar() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  public changeLanguage(lang) {
    this.translate.use(lang)
  }

  searchTerm(term: any) {
    term ? this.addFix() : this.removeFix();
    if (!term) return this.menuItems = [];
    let items = [];
    term = term.toLowerCase();
    this.items.filter(menuItems => {
      if (menuItems.title.toLowerCase().includes(term) && menuItems.type === 'link') {
        items.push(menuItems);
      }
      if (!menuItems.children) return false
      menuItems.children.filter(subItems => {
        if (subItems.title.toLowerCase().includes(term) && subItems.type === 'link') {
          subItems.icon = menuItems.icon
          items.push(subItems);
        }
        if (!subItems.children) return false
        subItems.children.filter(suSubItems => {
          if (suSubItems.title.toLowerCase().includes(term)) {
            suSubItems.icon = menuItems.icon
            items.push(suSubItems);
          }
        })
      })
      this.checkSearchResultEmpty(items)
      this.menuItems = items
    });
  }

  checkSearchResultEmpty(items) {
    if (!items.length)
      this.searchResultEmpty = true;
    else
      this.searchResultEmpty = false;
  }

  addFix() {
    this.searchResult = true;
    body.classList.add("offcanvas");
  }

  removeFix() {
    this.searchResult = false;
    body.classList.remove("offcanvas");
    this.text = "";
  }


  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  NewPassword: string = '';
  ConfirmPassword: string = '';

  OpenChangePassword(content) {

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {

      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  ChangePassword() {
    if (this.NewPassword == '') {
      this.toastrService.warning('Please enter new password.', 'Password Required');
      return false;
    } else if (this.NewPassword.length < 8) {
      this.toastrService.warning('Password should be minimum 8 characters.', 'Invalid');
      return false;
    } else if (this.NewPassword != this.ConfirmPassword) {
      this.toastrService.warning('Password and confirm password should be same.', 'Password not match');
      return false;
    } else {
      this.APIService.GetAPI('Auth/ChangePassword?UserId=' + this.APIService.objTokenDetails.id + '&NewPassword=' + this.NewPassword).subscribe(data => {
        if (data.response.statusCode == 1) {
          this.toastrService.success('Password has been changed successfully.', 'Password Changed');
          this.modalService.dismissAll();
        }
        else {
          this.toastrService.warning('No records found!', 'Not Found');
        }
      }, error => {
        this.toastrService.error('Something went Wrong', 'Error!');
      })

    }
  }
}
