<div class="page-wrapper">
	<div class="c-login-bg">
		<div class="authentication-box">
			
			<h2>Packing Report</h2>
            <button class="btn btn-danger btn-sm  mr-2" (click)="ExportPackaging()" style="float: right; margin-top: -40px;"><i class="fa fa-file-excel-o"
                aria-hidden="true"></i>
              Packaging</button>
            <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                      <th>SR NO</th>
                      <th>SKU</th>
                      <th>ITEM NAME</th>
                      <th>UNIT TYPE</th>
                      <th>purchase from farmers QUANTITY</th>
                    </tr>
                  </thead>

                  <tbody>
                    <ng-container *ngFor="let objParent of ExportOrderList;let i=index">
                      <tr>
                        <td style="vertical-align: middle"> {{i+1}}</td>
                        <td style="vertical-align: middle"> {{objParent.productName}}</td>
                        <td style="vertical-align: middle"> {{objParent.unitType}}</td>
                        <td style="vertical-align: middle"> {{objParent.quantity}}</td>
                        <td>{{objParent.finalWeight}}</td>
                      </tr>
                      
                    </ng-container>
                  </tbody>

            </table>
		</div>
	</div>
</div>