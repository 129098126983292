import { Component, OnInit } from '@angular/core';
import { APIService } from 'src/app/shared/services/api/api.service'
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-packing-report',
  templateUrl: './packing-report.component.html',
  styleUrls: ['./packing-report.component.scss']
})
export class PackingReportComponent implements OnInit {
  ExportPackagingList: any[] = [];
  ExportOrderList: any[] = [];
  ExportWherehouseList: any[] = [];

  constructor(private APIService: APIService, private toastrService: ToastrService, private ActivatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.ExportPackagingData();
  }


  ExportPackaging() {

    this.APIService.GetAPI('MobileMandiAdmin/GetDailyProcessingOrderList').subscribe(data => {
      if (data.response.data) {
        let exportData = data.response.data;
        exportData = exportData.map(({total, ...keepAttrs}) => keepAttrs)
        this.APIService.exportAsExcelFile(exportData, 'Processing Order- Packaging');
      }
    });    
  }

  ExportPackagingData() {

    this.APIService.GetAPI('MobileMandiAdmin/GetDailyProcessingOrderList').subscribe(data => {
      if (data.response.data) {
        this.ExportOrderList = data.response.data;
        //exportData = exportData.map(({total, ...keepAttrs}) => keepAttrs)
        //this.APIService.exportAsExcelFile(exportData, 'Processing Order- Packaging');
      }
    });    
  }

  // GetExportOrderList() {

  //   this.APIService.GetAPI('Cart/GetOrderItemAllList?OrderType=Processing').subscribe(data => {
  //     debugger
  //     if (data.response.data) {
  //       this.ExportOrderList = data.response.data.filter(x => x.status == "Processing");
  //       this.ExportOrderList = this.ExportOrderList.sort((n1, n2) => {
  //         if (n1.customerAddress > n2.customerAddress) {
  //           return 1;
  //         }

  //         if (n1.customerAddress < n2.customerAddress) {
  //           return -1;
  //         }

  //         return 0;
  //       });

  //       var TempArray = this.APIService.GroupByData(this.ExportOrderList, 'sku')
  //       debugger
  //       this.ExportWherehouseList = [];
  //       for (var i = 0; i < TempArray.length; i++) {

  //         let sum: number = TempArray[i].value.map(a => a.quantity).reduce(function (a, b) {
  //           return a + b;
  //         });

  //         let weightSumGm: number = 0;
  //         let weightSumKg: number = 0;
  //         let weightSumOther: number = 0;
  //         let FinalWeight: string = '';
  //         for (let j = 0; j < TempArray[i].value.length; j++) {
  //           if (TempArray[i].value[j].productUnitType.toLowerCase() == 'gm') {
  //             weightSumGm = weightSumGm + (TempArray[i].value[j].productWeight * TempArray[i].value[j].quantity);
  //           } else if (TempArray[i].value[j].productUnitType.toLowerCase() == 'kg') {
  //             weightSumKg = weightSumKg + (TempArray[i].value[j].productWeight * TempArray[i].value[j].quantity);
  //           } else {
  //             weightSumOther = weightSumOther + (TempArray[i].value[j].productWeight * TempArray[i].value[j].quantity);
  //           }
  //         }


  //         if (TempArray[i].value[0].productUnitType.toLowerCase() == 'gm' || TempArray[i].value[0].productUnitType.toLowerCase() == 'kg') {
  //           FinalWeight = (weightSumKg + (weightSumGm / 1000)).toString() + ' Kg'
  //         } else {
  //           FinalWeight = weightSumOther.toString() + ' ' + TempArray[i].value[0].productUnitType;
  //         }


  //         let obj = {
  //           SR: i + 1,
  //           CATEGORY: TempArray[i].value[0].category,
  //           SKU: TempArray[i].value[0].sku,
  //           ITEM_NAME: TempArray[i].value[0].productName,
  //           'NO. OF PACK': sum,
  //           UNIT_TYPE: TempArray[i].value[0].unitType,
  //           TOTAL_ITEM_WEIGHT: FinalWeight
  //         }

  //         this.ExportWherehouseList.push(obj);
  //       }
  //     }
  //     debugger;
  //       let r = this.ExportWherehouseList;
  //   }, error => {
  //     this.toastrService.error('Something went Wrong', 'Error!');
  //   })

  // }

}
