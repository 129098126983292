import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NavService, Menu } from '../../services/nav.service';
import { APIService } from 'src/app/shared/services/api/api.service'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {
  public IsAdminMenu: boolean = false;
  public objMenuList: MenuMaster[] = [];
  public objMenuItem: MenuMaster = new MenuMaster();
  public menuItems: Menu[];
  public url: any;
  public fileurl: any;

  constructor(private router: Router, public navServices: NavService, public APIService: APIService) {
    //this.APIService.objTokenDetails.id

    // if (this.APIService.objTokenDetails.id == 29) {
    //   this.IsAdminMenu = true;
    // } else {
    //   this.APIService.GetAPI('MenuAccess/GetMenuViewUserAccessUserList?UserId=' + this.APIService.objTokenDetails.id).subscribe(data => {
    //     if (data.response.data) {
    //       this.APIService.MenuList = data.response.data;

    //       this.APIService.MenuList = this.APIService.MenuList.filter(x => x.isView == true && x.isShow == true);
    //       debugger
    //       let GroupedData = this.APIService.GroupByData(this.APIService.MenuList, 'parentMenuId');
    //       let ChildrenData = [];
    //       this.objMenuList = [];
    //       for (var i = 0; i < GroupedData.length; i++) {


    //         if (GroupedData[i].key != "null" && GroupedData[i].key != "0") {

    //           for (var j = 0; j < GroupedData[i].value.length; j++) {
    //             let SubMenu: ChildMenuMaster = new ChildMenuMaster();
    //             SubMenu.title = GroupedData[i].value[j].menuName;
    //             SubMenu.icon = GroupedData[i].value[j].icon;
    //             SubMenu.path = GroupedData[i].value[j].menuUrl;
    //             SubMenu.type = 'link';
    //             SubMenu.parentId = GroupedData[i].value[j].parentMenuId;
    //             SubMenu.menuId = GroupedData[i].value[j].menuId;
    //             SubMenu.displayOrder = GroupedData[i].value[j].displayOrder;
    //             ChildrenData.push(SubMenu);
    //           }


    //         } else {
    //           for (var j = 0; j < GroupedData[i].value.length; j++) {
    //             let SingleMenu: MenuMaster = new MenuMaster();
    //             SingleMenu.title = GroupedData[i].value[j].menuName;
    //             SingleMenu.icon = GroupedData[i].value[j].icon;
    //             SingleMenu.path = GroupedData[i].value[j].menuUrl;
    //             SingleMenu.type = 'link';
    //             SingleMenu.menuId = GroupedData[i].value[j].menuId;
    //             SingleMenu.parentId = 0;
    //             SingleMenu.displayOrder = GroupedData[i].value[j].displayOrder;
    //             this.objMenuList.push(SingleMenu);
    //           }
    //         }
    //       }

    //       for (var k = 0; k < this.objMenuList.length; k++) {
    //         if (ChildrenData.filter(x => x.parentId == this.objMenuList[k].menuId).length > 0) {
    //           this.objMenuList[k].children = ChildrenData.filter(x => x.parentId == this.objMenuList[k].menuId).sort((n1, n2) => {
    //             if (n1.displayOrder > n2.displayOrder) {
    //               return 1;
    //             }

    //             if (n1.displayOrder < n2.displayOrder) {
    //               return -1;
    //             }

    //             return 0;
    //           });
    //           delete this.objMenuList[k].path;
    //           this.objMenuList[k].type = 'sub';
    //         } else {
    //           delete this.objMenuList[k].children;
    //         }
    //       }

    //       this.objMenuList = this.objMenuList.sort((n1, n2) => {
    //         if (n1.displayOrder > n2.displayOrder) {
    //           return 1;
    //         }

    //         if (n1.displayOrder < n2.displayOrder) {
    //           return -1;
    //         }

    //         return 0;
    //       });

    //       console.log(JSON.stringify(this.objMenuList))
    //       debugger


    //       // this.router.events.subscribe((event) => {
    //       //   debugger
    //       //   if (event instanceof NavigationEnd) {
    //       //     this.objMenuList.filter(items => {
    //       //       if (items.path === event.url)
    //       //         this.setNavActive(items)
    //       //       if (!items.children) return false
    //       //       items.children.filter(subItems => {
    //       //         if (subItems.path === event.url)
    //       //           this.setNavActive(subItems)
    //       //         // if (!subItems.children) return false
    //       //         // subItems.children.filter(subSubItems => {
    //       //         //   if (subSubItems.path === event.url)
    //       //         //     this.setNavActive(subSubItems)
    //       //         // })
    //       //       })
    //       //     })
    //       //   }
    //       // })
    //     }
    //   },
    //     err => {

    //     })
    // }
    
    this.APIService.GetAPI('MenuAccess/GetMenuViewUserAccessUserList?UserId=' + this.APIService.objTokenDetails.id).subscribe(data => {
      if (data.response.data) {
        this.APIService.MenuList = data.response.data;

        this.APIService.MenuList = this.APIService.MenuList.filter(x => x.isView == true && x.isShow == true);
        
        let GroupedData = this.APIService.GroupByData(this.APIService.MenuList, 'parentMenuId');
        let ChildrenData = [];
        this.objMenuList = [];
        for (var i = 0; i < GroupedData.length; i++) {


          if (GroupedData[i].key != "null" && GroupedData[i].key != "0") {

            for (var j = 0; j < GroupedData[i].value.length; j++) {
              let SubMenu: ChildMenuMaster = new ChildMenuMaster();
              SubMenu.title = GroupedData[i].value[j].menuName;
              SubMenu.icon = GroupedData[i].value[j].icon;
              SubMenu.path = GroupedData[i].value[j].menuUrl;
              SubMenu.type = 'link';
              SubMenu.parentId = GroupedData[i].value[j].parentMenuId;
              SubMenu.menuId = GroupedData[i].value[j].menuId;
              SubMenu.displayOrder = GroupedData[i].value[j].displayOrder;
              ChildrenData.push(SubMenu);
            }


          } else {
            for (var j = 0; j < GroupedData[i].value.length; j++) {
              let SingleMenu: MenuMaster = new MenuMaster();
              SingleMenu.title = GroupedData[i].value[j].menuName;
              SingleMenu.icon = GroupedData[i].value[j].icon;
              SingleMenu.path = GroupedData[i].value[j].menuUrl;
              SingleMenu.type = 'link';
              SingleMenu.menuId = GroupedData[i].value[j].menuId;
              SingleMenu.parentId = 0;
              SingleMenu.displayOrder = GroupedData[i].value[j].displayOrder;
              this.objMenuList.push(SingleMenu);
            }
          }
        }

        for (var k = 0; k < this.objMenuList.length; k++) {
          if (ChildrenData.filter(x => x.parentId == this.objMenuList[k].menuId).length > 0) {
            this.objMenuList[k].children = ChildrenData.filter(x => x.parentId == this.objMenuList[k].menuId).sort((n1, n2) => {
              if (n1.displayOrder > n2.displayOrder) {
                return 1;
              }

              if (n1.displayOrder < n2.displayOrder) {
                return -1;
              }

              return 0;
            });
            delete this.objMenuList[k].path;
            this.objMenuList[k].type = 'sub';
          } else {
            delete this.objMenuList[k].children;
          }
        }

        this.objMenuList = this.objMenuList.sort((n1, n2) => {
          if (n1.displayOrder > n2.displayOrder) {
            return 1;
          }

          if (n1.displayOrder < n2.displayOrder) {
            return -1;
          }

          return 0;
        });

      

        let currentURL = this.router.url.split('?')[0];

        currentURL = currentURL.replace('details', 'list').replace('image', 'list')
        .replace('change-role', 'user-list').replace('e-cash', 'user-list').replace('e-wallet', 'user-list');
        this.APIService.GetMenuRights(currentURL)

        this.menuItems = this.objMenuList
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            
            let navigatedURL = this.router.url.split('?')[0];

            navigatedURL = navigatedURL.replace('details', 'list').replace('image', 'list').replace('change-role', 'user-list')
            .replace('e-cash', 'user-list').replace('e-wallet', 'user-list');           
            this.APIService.GetMenuRights(navigatedURL)
            this.menuItems.filter(items => {
              if (items.path === event.url)
                this.setNavActive(items)
              if (!items.children) return false
              items.children.filter(subItems => {
                if (subItems.path === event.url)
                  this.setNavActive(subItems)
                if (!subItems.children) return false
                subItems.children.filter(subSubItems => {
                  if (subSubItems.path === event.url)
                    this.setNavActive(subSubItems)
                })
              })
            })
          }
        })

        // this.router.events.subscribe((event) => {
        //   debugger
        //   if (event instanceof NavigationEnd) {
        //     this.objMenuList.filter(items => {
        //       if (items.path === event.url)
        //         this.setNavActive(items)
        //       if (!items.children) return false
        //       items.children.filter(subItems => {
        //         if (subItems.path === event.url)
        //           this.setNavActive(subItems)
        //         // if (!subItems.children) return false
        //         // subItems.children.filter(subSubItems => {
        //         //   if (subSubItems.path === event.url)
        //         //     this.setNavActive(subSubItems)
        //         // })
        //       })
        //     })
        //   }
        // })
      }
    },
      err => {

      })



    //this.navServices.items.subscribe(menuItems => {



    //})
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
  }
  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
    }
    item.active = !item.active
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    }
  }

}


export class MenuMaster {
  public title: string = ''
  public icon: string = ''
  public type: string = ''
  public path: string = ''
  public active: boolean = false
  public parentId: number = 0
  public menuId: number = 0
  public children: ChildMenuMaster[] = []
  public displayOrder: number
}

export class ChildMenuMaster {
  public title: string = ''
  public icon: string = ''
  public type: string = ''
  public path: string = ''
  public active: boolean = false
  public parentId: number = 0
  public menuId: number = 0
  public displayOrder: number
}

