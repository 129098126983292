<div class="page-wrapper">
	<div class="auth-bg c-login-bg">
		<div class="authentication-box">
			
			<div class="card mt-4">
				<div class="card-body">
					<div class="text-center"><img src="assets/images/logo.png" style="height:135px;" alt=""></div>
					<div class="text-center">
						<h4 style="margin-top: 15px;">LOGIN</h4>
						<h6>Enter your Username and Password </h6>
					</div>
					<form class="theme-form" [formGroup]="loginForm" *ngIf="!newUser">
						<div class="form-group">
							<label class="col-form-label pt-0">Username</label>
							<input class="form-control" formControlName="userName" placeholder="Username"  type="text" required="">
							<div *ngIf="loginForm.controls.userName.touched && loginForm.controls.userName.errors?.required"
								class="text text-danger mt-1">
								Username is required
							</div>
							
						</div>
						<div class="form-group">
							<label class="col-form-label">Password</label>
							<input class="form-control" type="password" formControlName="password" placeholder="Password" required="">
							<div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
								class="text text-danger mt-1">
								Password is required
							</div>
						</div>
						<!-- <div class="checkbox p-0 text-right">
							
							<a class="col-form-label lbl-forgot" [routerLink]="['/password']">Forgot Password?</a>
						</div> -->
						<div class="form-group form-row mt-3 mb-0">
							<button class="btn btn-primary btn-block btn-lg"
								[class.loader--text]="authService.showLoader"
								[disabled]="!loginForm.valid || authService.showLoader" (click)="login(loginForm.value)"
								type="submit"><span>{{ authService.showLoader ? '' : 'Login' }}</span>
							</button>
						</div>
						<!-- <span style="color: red;
						font-size: 14px;
						text-align: center;
						display: block;
						margin-top: 10px;">Your theme template license will <b>Expire on 20 July,2023</b>. Please get in touch with <b>Envato Marketplace or your service provider</b> for easy access</span>
						 -->
						<!-- <div class="login-divider"></div> -->
						<!-- <div class="social mt-3">
							<div class="form-group btn-showcase d-flex">
								<button class="btn social-btn btn-fb d-inline-block" (click)="loginFacebook()">
									<i class="fa fa-facebook"></i>
								</button>
								<button class="btn social-btn btn-twitter d-inline-block" (click)="loginTwitter()">
									<i class="fa fa-twitter"></i>
								</button>
								<button class="btn social-btn btn-google d-inline-block" (click)="loginGoogle()">
									<i class="fa fa-google"></i>
								</button>
							</div>
						</div> -->
					</form>
				</div>
			</div>
		</div>
	</div>
</div>