<!-- Loader starts-->
<!-- <div class="loader-wrapper" [class.loderhide]="!show">
  <div class="loader bg-white">
    <div class="whirly-loader"> </div>
  </div>
</div> -->
<!-- Loader ends-->




<div id="loader" *ngIf="APIService.IsLoading">
  <img src="../../../../assets/images/loader-3.gif" alt="MM" class=" loader-img" width="100px">
</div>