import { Component, OnInit, OnDestroy } from '@angular/core';
import { APIService } from '../../services/api/api.service'

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {



  constructor(public APIService: APIService) {


  }
  ngOnInit() {
  }
}
