<!DOCTYPE html>
<html lang="en">

<head>
    <title>Registration Form</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">

    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
</head>

<body>
    <div>
        <img src='http://gomandi.com/assets/images/logo.png' class="theme-logo" />
    </div>
    <div class="form_wrapper">
        <div class="form_container">
            <div class="title_container">
                <h2>Welcome To Sabjivale.in</h2>
                <h2 class="theme-color">User Sign Up</h2>
            </div>
            <div class="row clearfix" >
                <div class="row" style="width: 100%;">
                    <form class="col-md-12" #userForm="ngForm" (ngSubmit)="userForm.form.valid && onSubmit()"  novalidate>                        
                        <!-- <div class="row clearfix">
                            <div class="col_half">
                                <div class="input_field"> <span><i aria-hidden="true" class="fa fa-user"></i></span>
                                    <input type="text" name="name" placeholder="First Name" />
                                </div>
                            </div>
                            <div class="col_half">
                                <div class="input_field"> <span><i aria-hidden="true" class="fa fa-user"></i></span>
                                    <input type="text" name="name" placeholder="Last Name" required />
                                </div>
                            </div>
                        </div> -->
                        <div class="input_field"  [ngClass]="{'c-error':(FirstName.touched && FirstName.errors?.required) || (FirstName.errors?.required && userForm.submitted)}"> 
                            <span><i aria-hidden="true" class="fa fa-user"></i></span>
                            <input type="text" #FirstName="ngModel" [(ngModel)]="objRegistration.FirstName" name="FirstName" 
                             placeholder="First Name" required
                            />                             
                        </div>
                        <div class="input_field" [ngClass]="{'c-error':(LastName.touched && LastName.errors?.required) || (LastName.errors?.required && userForm.submitted)}">
                             <span><i aria-hidden="true" class="fa fa-user"></i></span>
                            <input type="text" #LastName="ngModel" [(ngModel)]="objRegistration.LastName" name="LastName"
                             placeholder="Last Name" required />
                        </div>
                        <div class="input_field" [ngClass]="{'c-error':(Contact.touched && Contact.errors?.required) || (Contact.errors?.required && userForm.submitted)}"> 
                            <span><i aria-hidden="true" class="fa fa-phone"></i></span>
                            <input type="number" maxlength="10" #Contact="ngModel" [(ngModel)]="objRegistration.Contact" name="Contact"
                             name="Contact" placeholder="Contact Number" required [disabled]="IsOTPSend" />
                        </div>
                        <div class="input_field select_option" [ngClass]="{'c-error':(SocietyId.touched && SocietyId.errors?.required) || (SocietyId.errors?.required && userForm.submitted)}">
                            <span><i aria-hidden="true"
                                    class="fa fa-home"></i></span>
                            <!-- <select #SocietyId="ngModel" name="SocietyId" [(ngModel)]="objRegistration.SocietyId" required>
                                <option value="">Select Society</option>
                                <option *ngFor="let obj of SocietyList" [value]="obj.id">{{obj.societyName}}</option>                                
                            </select> -->

                            <ng-select2 [placeholder]="'Select Society'" class="form-control-select" id="SocietyId" #SocietyId="ngModel"
                            [(ngModel)]="objRegistration.SocietyId" name="SocietyId" required [options]="options">
                            <option value="">Select</option>
                            <option [value]="obj.id" *ngFor="let obj of SocietyList">{{obj.societyName}}
                            </option>
                        </ng-select2>

                            <!-- <div class="select_arrow"></div> -->
                        </div>
                        <div class="input_field" *ngIf="RegistrationType=='child'"> <span><i aria-hidden="true" class="fa fa-refresh"></i></span>
                            <input type="text" name="parent" #ParentReferralCode="ngModel" name="ParentReferralCode" [(ngModel)]="objRegistration.ParentReferralCode" placeholder="Parent Referal Code"
                                readonly="readonly" />
                        </div>
                        <div *ngIf="IsOTPSend" class="input_field" [ngClass]="{'c-error':(OTP.touched && OTP.errors?.required) || (OTP.errors?.required && userForm.submitted)}"> 
                            <span><i aria-hidden="true" class="fa fa-key"></i></span>
                            <input type="text" maxlength="10" #OTP="ngModel" [(ngModel)]="objRegistration.OTP" name="OTP"
                             name="OTP" placeholder="OTP" required />
                        </div>
                        <div *ngIf="IsOTPSend" class="input_field" [ngClass]="{'c-error':(Password.touched && Password.errors?.required) || (Password.errors?.required && userForm.submitted)}"> 
                            <span><i aria-hidden="true" class="fa fa-lock"></i></span>
                            <input type="password" #Password="ngModel" name="Password" [(ngModel)]="objRegistration.Password" placeholder="Password" required />
                        </div>
                        <input class="button" type="submit"  [value]="IsOTPSend==false?'Send OTP':'Register'" />                        
                    </form>
                </div>
            </div>
        </div>
    </div>
    <p class="credit">Provide by <a href="http://ramukakafresh.com/" target="_blank">Ramukaka Product and Service Pvt
            Ltd</a></p>

</body>

</html>