import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { APIService } from 'src/app/shared/services/api/api.service'
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Options } from 'select2';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  objRegistration: Registration = new Registration();
  BtnText: string = 'Send OTP';
  IsOTPSend: boolean = false;
  SocietyList: any[] = [];
  ReceivedOTP: string = '';
  RegistrationType: string = 'child';
  public options: Options;
  constructor(private fb: FormBuilder, private APIService: APIService, private ActivatedRoute: ActivatedRoute, private Router: Router, private toastrService: ToastrService) { 
    this.GetSocietyList();
  }

  ngOnInit(): void {
    this.options = {
      // multiple: false,
      // // theme: 'classic',
      // closeOnSelect: false,
      width: '100%'  
    };
   
    if (this.ActivatedRoute.snapshot.queryParamMap.get('code') != null) {
      this.RegistrationType = 'child'
      this.objRegistration.ParentReferralCode = this.ActivatedRoute.snapshot.queryParamMap.get('code');
    } else if (this.ActivatedRoute.snapshot.queryParamMap.get('pcode') != null) {
      this.RegistrationType = 'parent'
      this.objRegistration.ReferralCode = this.ActivatedRoute.snapshot.queryParamMap.get('pcode');
      this.objRegistration.UserGroupType = this.ActivatedRoute.snapshot.queryParamMap.get('type');
      
    }

  }

  onSubmit() {
    debugger
    if (!this.IsOTPSend) {
      this.APIService.GetAPI('Auth/SendOTP?Contact=' + this.objRegistration.Contact).subscribe(data => {
        if (data.response.statusCode == 0) {
          this.toastrService.warning(data.response.message, 'Already Exists!!')
        }
        else if (data.response.statusCode == 1) {
          this.toastrService.success('OTP has been sent to Mobile number.', 'OTP Sent');
          this.IsOTPSend = true;
          this.ReceivedOTP = data.response.data.otp;
          this.BtnText = 'Register';
        }

      }, error => {
        this.toastrService.error('Something went Wrong', 'Error!');
      })
    } else {
      if (this.ReceivedOTP != this.objRegistration.OTP) {
        this.toastrService.warning('OTP not mached.', 'OTP');
      } else {
        let URL = 'Auth/'
        if (this.RegistrationType == 'child') {
          URL = 'Auth/Register?SocietyId=' + this.objRegistration.SocietyId + '&FirstName=' + this.objRegistration.FirstName + '&LastName=' + this.objRegistration.LastName
            + '&Contact=' + this.objRegistration.Contact + '&Password=' + this.objRegistration.Password + '&ParentReferralCode=' + this.objRegistration.ParentReferralCode
        }else{
          URL = 'Auth/GroupUserRegister?SocietyId=' + this.objRegistration.SocietyId + '&FirstName=' + this.objRegistration.FirstName + '&LastName=' + this.objRegistration.LastName
          + '&Contact=' + this.objRegistration.Contact + '&Password=' + this.objRegistration.Password + '&ParentReferralCode=' + this.objRegistration.ParentReferralCode+ '&ReferralCode=' + this.objRegistration.ReferralCode
          + '&type=' + this.objRegistration.UserGroupType
        }
      
        this.APIService.PostAPI(URL, {}).subscribe(data => {
          if (data.response.statusCode == 0) {
            this.toastrService.warning(data.response.message, 'Warning')
          }
          else if (data.response.statusCode == 1) {
            Swal.fire({
              title: 'Registered Successfully.',
              text: "Please download mobile application",
              icon: 'success',
            }).then((result) => {
              window.location.href = this.APIService.MobileAppURL;
            })
          }
        }, error => {
          this.toastrService.error('Something went Wrong', 'Error!');
        })
      }
    }


  }


  GetSocietyList() {

    this.APIService.GetAPI('MasterSettingValues/GetSocietyList').subscribe(data => {
      if (data.response.data) {
        this.SocietyList = data.response.data;
      }

    }, error => {
      this.toastrService.error('Something went Wrong', 'Error!');
    })

  }

}

export class Registration {

  public SocietyId: string = ''
  public FirstName: string = ''
  public LastName: string = ''
  public Contact: string = ''
  public Password: string = ''
  public ParentReferralCode: string = ''
  public ReferralCode: string = ''
  public OTP: string = '';
  public UserGroupType: string = '';
}
