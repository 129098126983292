import { Injectable } from '@angular/core';
import { Invoice } from '../../../shared/model/e-commerce/invoice.model'
import { CartItem } from '../../../shared/model/e-commerce/cart.model';
import { BehaviorSubject, Subscriber } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { DatePipe } from '@angular/common';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment'
import * as CryptoJS from 'crypto-js';
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver';
import * as moment from 'moment'
import { GroupByPipe } from '../pipes/groupBy.pipe'
@Injectable({
  providedIn: 'root'
})

export class APIService {
  public objTokenDetails: UserTokenModel = new UserTokenModel();
  file: any;
  IsLoading: boolean = false;
  arrayBuffer: any;
  EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  EXCEL_EXTENSION = '.xlsx';
  encryptSecretKey: string = 'GoMandi'
  BaseURL: string;
  MenuList: any[] = [];
  MenuRights: MenuRighsModel = new MenuRighsModel();
  DefaultImgaURL: string = '../../../../assets/images/No_Image_Available.jpg'
  MobileAppURL: string = 'https://play.google.com/store/apps/details?id=com.mobilemandi';
  QRCodeURL: string = 'https://gomandi.com/'
  headers = new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*');
  Formheaders = new HttpHeaders()
    .set('content-type', 'multipart/form-data; boundary=----WebKitFormBoundaryWmt0le2BezUauhxg')
    .set('Accept', 'application/json');

  constructor(private router: Router, private http: HttpClient, private datePipe: DatePipe, private GroupByPipe: GroupByPipe) {
    this.BaseURL = environment.baseURL;
    if (localStorage.getItem('token') != null) {
      this.objTokenDetails = JSON.parse(localStorage.getItem('token'))
    }
  }

  GetMenuRights(MenuURL: string) {
    this.MenuRights = this.MenuList.filter(x => x.menuUrl == MenuURL).length > 0 ? this.MenuList.filter(x => x.menuUrl == MenuURL)[0] : new MenuRighsModel();
    return this.MenuRights;
  }

  EncryptData(data) {

    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecretKey).toString();
    } catch (e) {
      console.log(e);
    }
  }

  DecryptData(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  FormatDate(date, format) {
    return this.datePipe.transform(date, format);
  }

  ConvertStringToDate(StrDate) {
    StrDate = StrDate.replace('T', ':')
    let DtArray = StrDate.split(/[- :]/);

    let DateObj = new Date(Date.UTC(DtArray[0], DtArray[1] - 1, DtArray[2]));
    return DateObj
  }

  FormatTime(time, format) {
    return moment(time).format(format);
  }

  GroupByData(ArrayValue, Key) {
    return this.GroupByPipe.transform(ArrayValue, Key);
  }

  RemoveColumns(arrayList: any[], columns: string) {
    debugger
    let columnsArray: any[] = columns.split(',')
    arrayList.forEach(object => {
      for (var property in object) {
        if (columnsArray.find(x => x == property)) {
          delete object[property];
        }
      }
    });

    return arrayList;
  }

  ReadData(objFile) {
    this.file = objFile;
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      //console.log(XLSX.utils.sheet_to_json(worksheet,{raw:true}));    
      var arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      return arraylist;
    }
  }

  public ExportTableToExcel(Table: any, FileName: string) {

    let element = document.getElementById('exportTable');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(Table);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, FileName + '_export_' + this.FormatDate(new Date(), 'dd_MM_yyyy_hh_mm_ss') + '.xlsx');
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: this.EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + this.FormatDate(new Date(), 'dd_MM_yyyy_hh_mm_ss') + this.EXCEL_EXTENSION);
  }

  public removeUnwantedColumns(arrayList: any[]) {
    arrayList.forEach(object => {
      delete object['id'];
      delete object['userId'];
      delete object['isVisible'];
      delete object['isdelete'];
    });
    return arrayList;
  }


  GetAPI(url: string) {
    return this.http.get(this.BaseURL + url, { 'headers': this.headers }).map((res: any) => {
      return res;
    });
  }

  PostAPI(url: string, postObj: any) {
    return this.http.post(this.BaseURL + url, postObj, { 'headers': this.headers }).map((res: any) => {
      return res;
    });
  }

  PostAPIWithFormData(url: string, postObj: any, objFormData: any) {
    // var formData: any = new FormData();
    // formData.append('fileOne', objFormData);
    console.log(this.BaseURL + url)
    return this.http.post(this.BaseURL + url, objFormData).map((res: any) => {
      return res;
    });
  }

  SetUserToken(objData: any) {
    this.objTokenDetails = objData;
    localStorage.setItem('token', JSON.stringify(objData))
  }

  GetUserToken() {
    this.objTokenDetails = JSON.parse(localStorage.getItem('token'))
    return this.objTokenDetails;
  }

  RemoveUserToken() {
    localStorage.clear();
  }

  filterDataFromDatatable(ArrayList: any[]) {
    let TxtSearch: any = document.querySelector('.dataTables_filter input');
    let searchText = TxtSearch.value
    const val = searchText.toLowerCase();
    let keys = Object.keys(ArrayList[0]);
    let colsAmt = keys.length;
    // let keys = Object.keys(this.UserList[0]);
    // filter our data

    const temp = ArrayList.filter(function (d) {

      for (let i = 0; i < colsAmt; i++) {
        // check for a match
        if (d[keys[i]] != null) {

          // check for a match
          if (d[keys[i]].toString().toLowerCase().indexOf(val) !== -1 || !val) {
            // found match, return true to add to result set
            return true;
          }
        }
      }
    });

    return temp
  }

}



export class UserTokenModel {
  public token: string = ''
  public userName: string = ''
  public validaty: string = ''
  public refreshToken: string = ''
  public id: number = 0
  public emailId: string = ''
  public guidId: string = ''
  public expiredTime: string = ''
}


export class MenuRighsModel {
  public userId: number = 0
  public menuId: number = 0
  public menuName: number = 0
  public menuUrl: string = ''
  public icon: string = ''
  public parentMenuId: number = 0
  public isShow: boolean = false
  public displayOrder: number = 0
  public isView: boolean = false
  public isAdd: boolean = false
  public isEdit: boolean = false
  public isDelete: boolean = false
}