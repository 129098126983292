import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../../shared/services/firebase/auth.service';
import { APIService } from 'src/app/shared/services/api/api.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr'
import Swal from 'sweetalert2';
type UserFields = 'email' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public newUser = false;
  public user: firebase.User;
  public loginForm: FormGroup;
  public formErrors: FormErrors = {
    'email': '',
    'password': '',
  };
  public errorMessage: any;

  constructor(public authService: AuthService,
    private afauth: AngularFireAuth, private fb: FormBuilder,
    private router: Router, private APIService: APIService,
    private ActivatedRoute: ActivatedRoute,
    private toastrService: ToastrService
  ) {
    this.loginForm = fb.group({
      userName: ['', [Validators.required]],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.APIService.RemoveUserToken();
  }

  // Login With Google
  loginGoogle() {
    this.authService.GoogleAuth();
  }

  // Login With Twitter
  loginTwitter(): void {
    this.authService.signInTwitter();
  }

  // Login With Facebook
  loginFacebook() {
    this.authService.signInFacebok();
  }

  // Simple Login
  login(loginForm) {
    debugger

    let URL = 'Account/GetAdminToken'
    let PostObject = {
      userName: loginForm.userName,
      password: loginForm.password
    }
    this.APIService.PostAPI(URL, PostObject).subscribe(data => {
      debugger
      this.router.navigateByUrl('/dashboard');
      this.APIService.SetUserToken(data.response.data)
      if (data.response.statusCode == 0) {
        this.toastrService.error(data.response.message, 'Invalid!');
      }
      else if (data.response.statusCode == 1) {

        this.APIService.GetAPI('Cart/GetOrderAllListWithOrderStatus?OrderStatus=Processing').subscribe(orderData => {
          if (orderData.response.data && orderData.response.data.length > 0) {
            Swal.fire('Pending Orders', 'You have ' + orderData.response.data.length + ' pending orders.', 'info').then((result) => {
              this.router.navigateByUrl('order/new-order');
            })
          }
        })

        //this.toastrService.success(data.response.message, 'Success!');               
      }
      if (data.response.statusCode == -1) {
        this.toastrService.error(data.response.message, 'Error!');
      }
    }, err => {

      this.toastrService.error('Something went Wrong', 'Error!');
    })
  }


  //debugger
  //this.router.navigateByUrl('/dashboard/default');
  //this.authService.SignIn(this.loginForm.value['email'], this.loginForm.value['password']);
  // }

}
