<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 footer-copyright">
      <p class="mb-0">Copyright 2023 © Realbasket.in All rights reserved.</p>
    </div>
    <div class="col-md-6">
      <p class="pull-right mb-0"><a href="https://invisionsoftwaresolution.in/" target="_blank">Develop and design by Invision Software Solution</a><i class="fa fa-heart"></i></p>
    </div>
  </div>
</div>